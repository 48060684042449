import React from "react";
import { Result, Button, Typography, Space } from "antd";

const { Title, Paragraph, Text } = Typography;

const SuccessScreen = ({ userEmail }) => (
  <Result
    status="success"
    title="Willkommen in der MindElevate-Community! 🎉"
    subTitle={
      <div style={{ textAlign: "center" }}>
        <Title level={4}>Schön, dass du dabei bist!</Title>
        <Paragraph>
          Lade jetzt die App herunter, logge dich unter{" "}
          <Text strong>„Ich habe bereits ein Konto"</Text> mit deinen
          Zugangsdaten ein und starte deine persönliche Reise zu mehr
          Wohlbefinden und innerer Ruhe – mit deinem individuellen
          Trainingsplan.
        </Paragraph>
        {userEmail && (
          <Paragraph>
            Wir haben eine Bestätigungs-E-Mail an{" "}
            <Text strong>{userEmail}</Text> gesendet. Bitte bestätige deine
            E-Mail-Adresse, um die Registrierung abzuschließen.
          </Paragraph>
        )}
        <Space direction="vertical" align="center" style={{ width: "100%" }}>
          <Text type="secondary">
            Du findest die App im App Store oder bei Google Play:
          </Text>
          <div className="store-buttons-container">
            <a
              href="https://apps.apple.com/de/app/mindelevate/id6689505568"
              target="_blank"
              rel="noopener noreferrer"
              className="store-button"
            >
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1589414400"
                alt="Download on the App Store"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mindelevate.app&hl=de"
              target="_blank"
              rel="noopener noreferrer"
              className="store-button"
            >
              <img
                src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
                alt="Get it on Google Play"
              />
            </a>
          </div>
        </Space>
      </div>
    }
  />
);

export default SuccessScreen;
