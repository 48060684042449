import { Layout } from "antd";
import { InstagramOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <Footer
      style={{
        background: "#fff",
        padding: "40px 0",
        marginTop: "auto",
      }}
    >
      <div
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "40px",
          padding: "0 20px",
        }}
      >
        {/* MindElevate Column */}
        <div>
          <h3 style={{ marginBottom: "20px" }}>MindElevate</h3>
          <div
            style={{
              width: "100%",
              height: "2px",
              background: "#23C89A",
              marginBottom: "20px",
            }}
          />
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li style={{ marginBottom: "10px" }}>
              <a
                href="https://www.mindelevate.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                MindElevate Website
              </a>
            </li>
          </ul>
        </div>

        {/* Legal Column */}
        <div>
          <h3 style={{ marginBottom: "20px" }}>Rechtliches</h3>
          <div
            style={{
              width: "100%",
              height: "2px",
              background: "#23C89A",
              marginBottom: "20px",
            }}
          />
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li style={{ marginBottom: "10px" }}>
              <a
                href="https://www.mindelevate.app/datenschutz"
                target="_blank"
                rel="noopener noreferrer"
              >
                Datenschutzerklärung
              </a>
            </li>
            <li style={{ marginBottom: "10px" }}>
              <a
                href="https://www.mindelevate.app/impressum"
                target="_blank"
                rel="noopener noreferrer"
              >
                Impressum
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Footer>
  );
};

export default AppFooter;
