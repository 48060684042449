import axios from "axios";
import { refreshToken } from "../services/AuthService";
import { API_BASE_URL } from "../config/config";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

service.interceptors.request.use(
  async (config) => {
    // Skip token check for login and refresh token endpoints
    const isAuthRoute =
      config.url?.includes("/auth/login") ||
      config.url?.includes("/auth/refresh") ||
      config.url?.includes("/coupon");

    if (isAuthRoute) {
      console.log("Skipping checks for auth route:", config.url);
      return config;
    }

    const jwtToken = localStorage.getItem("token");

    if (!jwtToken) {
      console.log("No token found, redirecting to login");
      window.location.href = "/login";
      return Promise.reject("No token found");
    }

    config.headers["Authorization"] = "Bearer " + jwtToken;

    // Perform admin check on every request except the isAdmin endpoint itself
    if (!config.url?.includes("/auth/isAdmin")) {
      console.log("Performing admin check...");
      try {
        await service.get("/auth/isAdmin");
        console.log("Admin check passed");
      } catch (error) {
        console.log("Admin check failed:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        window.location.href = "/login";
        return Promise.reject(error);
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (error.config.url?.includes("/auth/login")) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      const refreshTokenValue = localStorage.getItem("refreshToken");
      if (refreshTokenValue) {
        try {
          const res = await refreshToken(refreshTokenValue);
          if (res) {
            localStorage.setItem("token", res.accessToken);
            localStorage.setItem("refreshToken", res.refreshToken);
            return service.request(error.config);
          }
        } catch (refreshError) {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/login";
          return Promise.reject(refreshError);
        }
      } else {
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  },
);

export default service;
