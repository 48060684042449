// import { useState } from "react";
import { Menu } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/MindElevateLogo.svg";
import {
	GroupOutlined,
	LogoutOutlined,
	NotificationOutlined,
	OrderedListOutlined,
	RobotOutlined,
	TagOutlined,
	TrophyOutlined,
	UserAddOutlined,
	UserOutlined,
	TeamOutlined,
} from "@ant-design/icons";
import DiaryQuestions from "../../pages/DiaryQuestions";

function Sidenav({ color }) {
	const { pathname } = useLocation();
	const page = pathname.replace("/", "");

	const dashboard = [
		<svg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' key={0}>
			<path
				d='M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z'
				fill={color}
			></path>
			<path
				d='M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z'
				fill={color}
			></path>
			<path
				d='M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z'
				fill={color}
			></path>
		</svg>,
	];

	const tables = [
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			key={0}
		>
			<path
				d='M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z'
				fill={color}
			></path>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z'
				fill={color}
			></path>
		</svg>,
	];

	const taskFlows = [
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
			<path
				fill={color}
				d='M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32l224 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-224 0c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32l224 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-224 0c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32l288 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-288 0c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z'
			/>
		</svg>,
	];

	return (
		<>
			<div className='brand'>
				<img src={logo} alt='' />
				<span>Mindelevate Dashboard</span>
			</div>
			<hr />
			<Menu theme='light' mode='inline'>
				<Menu.Item key='1'>
					<NavLink to='/dashboard'>
						<span
							className='icon'
							style={{
								background: page === "dashboard" ? color : "",
							}}
						>
							{dashboard}
						</span>
						<span className='label'>Dashboard</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='2'>
					<NavLink to='/content-editor'>
						<span
							className='icon'
							style={{
								background: page === "content-editor" ? color : "",
							}}
						>
							{tables}
						</span>
						<span className='label'>Content Editor</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='3'>
					<NavLink to='/task-flows'>
						<span
							className='icon'
							style={{
								background: page === "task-flows" ? color : "",
								padding: "5px",
							}}
						>
							{taskFlows}
						</span>
						<span className='label'>Taskflows</span>
					</NavLink>
				</Menu.Item>

				<Menu.Item key='4'>
					<NavLink to='/business-rule-engine'>
						<span
							className='icon'
							style={{
								background: page === "business-rule-engine" ? color : "",
								padding: "5px",
							}}
						>
							<RobotOutlined />
						</span>
						<span className='label'>Business Rule Engine</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='5'>
					<NavLink to='/tags-editor'>
						<span
							className='icon'
							style={{
								background: page === "tags-editor" ? color : "",
								padding: "5px",
							}}
						>
							<TagOutlined />
						</span>
						<span className='label'>Tags Editor</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='6'>
					<NavLink to='/categories'>
						<span
							className='icon'
							style={{
								background: page === "categories" ? color : "",
								padding: "5px",
							}}
						>
							<GroupOutlined />
						</span>
						<span className='label'>Kategorien</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='7'>
					<NavLink to='/achievement'>
						<span
							className='icon'
							style={{
								background: page === "achievement" ? color : "",
								padding: "5px",
							}}
						>
							<TrophyOutlined />
						</span>
						<span className='label'>Trophäen</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='8'>
					<NavLink to='/notification'>
						<span
							className='icon'
							style={{
								background: page === "notification" ? color : "",
								padding: "5px",
							}}
						>
							<NotificationOutlined />
						</span>
						<span className='label'>Push Notifications</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='9'>
					<NavLink to='/user-level'>
						<span
							className='icon'
							style={{
								background: page === "user-level" ? color : "",
								padding: "5px",
							}}
						>
							<UserOutlined />
						</span>
						<span className='label'>User Level</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='10'>
					<NavLink to='/diary-questions'>
						<span
							className='icon'
							style={{
								background: page === "diary-questions" ? color : "",
								padding: "5px",
							}}
						>
							<OrderedListOutlined />
							{/* <DiaryQuestions /> */}
						</span>
						<span className='label'>Fragen zum Tagebuch</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item key='11'>
					<NavLink to='/users'>
						<span
							className='icon'
							style={{
								background: page === "users" ? color : "",
								padding: "5px",
							}}
						>
							<TeamOutlined />
						</span>
						<span className='label'>Nutzer</span>
					</NavLink>
				</Menu.Item>

				<Menu.Item key='13'>
					{/* <NavLink to='/sign-in'>
						<span className='icon'>{signin}</span>
						<span className='label'>Login</span>
					</NavLink> */}
					<NavLink to='/userManagement'>
						<span className='icon'>
							<UserAddOutlined />
						</span>
						<span className='label'>User Management</span>
					</NavLink>
				</Menu.Item>
				<Menu.Item className='menu-item-header' key='12'>
					Account
				</Menu.Item>
				<Menu.Item key='11'>
					{/* <NavLink to='/sign-in'>
						<span className='icon'>{signin}</span>
						<span className='label'>Login</span>
					</NavLink> */}
					<NavLink
						to='/logout'
						onClick={() => {
							localStorage.clear();
							window.location.reload();
						}}
					>
						<span className='icon'>
							<LogoutOutlined />
						</span>
						<span className='label'>Logout</span>
					</NavLink>
				</Menu.Item>
			</Menu>
		</>
	);
}

export default Sidenav;
