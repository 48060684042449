import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography, message } from "antd";
import TaskForm from "../components/TaskFlow/TaskForm";
import TaskList from "../components/TaskFlow/TaskList";
import TaskFlowForm from "../components/TaskFlow/TaskFlowForm";
import { getCategories } from "../services/CategoryService";
import { deleteTaskflow, getTaskflows } from "../services/TaskflowService";
import { getTags } from "../services/TagsService";

const { Title } = Typography;

const TaskFlows = () => {
	const [taskFlows, setTaskFlows] = useState([]);
	const [allTaskflows, setAllTaskflows] = useState([]);
	const [currentTaskFlow, setCurrentTaskFlow] = useState({
		title: "",
		description: "",
		requiredTimeForTaskFlow: null,
		type: null,
		tasks: [],
		xp: null,
		categories: [],
		matchingParameters: [],
	});
	const [task, setTask] = useState({
		title: "",
		description: "",
		taskType: null,
		answers: [],
		file: null,
		articleText: "",
		challengeText: "",
		textMessage: "",
	});
	const [answerImage, setAnswerImage] = useState(null);
	const [answerTag, setAnswerTag] = useState("");
	const [editIndex, setEditIndex] = useState(null);
	const [taskEditIndex, setTaskEditIndex] = useState(null);
	const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);
	const [categories, setCategories] = useState([]);
	const [editTask, setEditTask] = useState(null);

	const taskTypes = [
		"MULTIPLE_CHOICE_QUESTION",
		"SINGLE_CHOICE_QUESTION",
		"FREE_TEXT_QUESTION",
		"INTERMEDIATE_SCREEN",
		"CHALLENGE",
		"CONFIRMATION",
		"ARTICLE",
		"VIDEO",
		"AUDIO",
		"IMAGE",
		"TEXT",
	];

	const taskTypesChat = [
		"SINGLE_CHOICE_QUESTION",
		"FREE_TEXT_QUESTION",
		"INTERMEDIATE_SCREEN",
		"WHATSAPP"
	]

	const [tagOptions, setTagOptions] = useState([]);

	const fetchTags = async () => {
		const res = await getTags();
		setTagOptions(res);
	  };

	  const handleAddTask = (newTask) => {
		if (taskEditIndex !== null) {
			// Wenn es sich um eine Bearbeitung handelt, aktualisiere die Task
			const updatedTasks = [...currentTaskFlow.tasks];
			updatedTasks[taskEditIndex] = newTask;
			setCurrentTaskFlow((prev) => ({
				...prev,
				tasks: updatedTasks,
			}));
			setTaskEditIndex(null); // Zurücksetzen des Index nach dem Speichern
		} else {
			// Neue Task wird in den aktuellen TaskFlow hinzugefügt
			setCurrentTaskFlow((prev) => ({
				...prev,
				tasks: [...prev.tasks, newTask],
			}));
		}

		setIsTaskModalVisible(false); // Modal schließen nach Hinzufügen der Task
	};


	const handleSaveTaskFlow = () => {
		if (editIndex !== null) {
			const updatedTaskFlows = [...taskFlows];
			updatedTaskFlows[editIndex] = currentTaskFlow;
			setTaskFlows(updatedTaskFlows);
			setEditIndex(null);
		} else {
			setTaskFlows([...taskFlows, currentTaskFlow]);
		}
		setCurrentTaskFlow({
			title: "",
			description: "",
			requiredTimeForTaskFlow: null,
			type: null,
			tasks: [],
			xp: 0,
			categories: [],

		});

		//fetch fresh taskflow list
		fetchTaskflows();


	};

	const handleEditTaskFlow = (taskFlow) => {
		const formattedCategories = taskFlow.categoryRequirements.map(req => {
			const catEarn = taskFlow.categoryParametersToEarn.find(earn => earn.categoryId === req.categoryId);
			const title = categories.find(cat => cat.id === req.categoryId).title;
			return {
				id: req.categoryId,
				taskTitle: title,
				min: req.minLevel,
				max: req.maxLevel,
				pointsToEarn: catEarn.valueToEarn,
			};
		})

		setCurrentTaskFlow({...taskFlow,
			results: taskFlow.results,
			userExperiencePointsToEarn: taskFlow.userExperiencePointsToEarn,
			categories: formattedCategories,
		});
		setEditIndex(taskFlows.findIndex((flow) => flow.id === taskFlow.id));
	};

	const showTaskModal = () => {
		// Clear task since this is to add a new task
		setTask({
			title: "",
			description: "",
			taskType: null,
			question: "",
			answers: [],
			file: null,
		});
		setIsTaskModalVisible(true);
	};

	const handleTaskModalCancel = () => {
		setIsTaskModalVisible(false);
		setTask({
			title: "",
			description: "",
			taskType: null,
			question: "",
			nextTaskId: null,
			answers: [],
			file: null,
		});
		setTaskEditIndex(null);

	};

	const handleFileUpload = (file) => {
		setTask({ ...task, file: URL.createObjectURL(file) });
		return false; // Prevent automatic upload
	};
	

	const removeFile = () => {
		setTask({ ...task, file: null });
	};

	const fetchCategories = async () => {
		const res = await getCategories();
		setCategories(res);
	};

	const fetchTaskflows = async () => {
		const res = await getTaskflows();
		setAllTaskflows(res);
	};

	const handleDeleteTaskFlow = async (taskflowId) => {
		await deleteTaskflow(taskflowId);
		message.success("Taskflow erfolgreich gelöscht");
		fetchTaskflows();
	};
	
	const handleEditTask = index =>{
		setTaskEditIndex(index);
		setEditTask(currentTaskFlow.tasks[index]);
		setIsTaskModalVisible(true);
	}

	useEffect(() => {
		fetchCategories();
		fetchTaskflows();
		fetchTags();
	}, []);

	return (
		<div className='layout-content'>
			<Row gutter={[24, 0]}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox '>
						<Title level={2}>Taskflow Editor</Title>
						<TaskFlowForm
							currentTaskFlow={currentTaskFlow}
							setCurrentTaskFlow={setCurrentTaskFlow}
							categories={categories}
							onShowTaskModal={showTaskModal}
							onSave={handleSaveTaskFlow}
							fetchTaskflows={fetchTaskflows}
							editMode={editIndex !== null}
							handleEditTask={handleEditTask}
							setEditIndex={setEditIndex}
							tagOptions={tagOptions}
						/>
					</Card>
				</Col>
			</Row>

			<Row gutter={[24, 0]} style={{ marginTop: "20px" }}>
				<Col xs={24}>
					<Card bordered={false} className='criclebox'>
						<TaskList taskflows={allTaskflows} onDelete={handleDeleteTaskFlow} onEdit={handleEditTaskFlow} categories={categories} />
					</Card>
				</Col>
			</Row>
			<TaskForm
				taskFlow={currentTaskFlow}
				taskTypes={currentTaskFlow.taskFlowType === 'CHAT' ? taskTypesChat : taskTypes}
				isTaskModalVisible={isTaskModalVisible}
				handleTaskModalCancel={handleTaskModalCancel}
				handleAddTask={handleAddTask}
				handleFileUpload={handleFileUpload}
				removeFile={removeFile}
				tagOptions={tagOptions}
				answerTag={answerTag}
				setAnswerTag={setAnswerTag}
				answerImage={answerImage}
				setAnswerImage={setAnswerImage}
				taskFlowList={allTaskflows}
				editTask={editTask}
				matchingParameters={currentTaskFlow.matchingParameters}

			/>
		</div>
	);
};

export default TaskFlows;
