import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography, Select } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import dayChart from "./configs/dailyLogins";
import { getAllUsers } from "../../services/UserService";

function DailyUserLoginChart() {
  const { Title } = Typography;
  const { Option } = Select;

  // Default: aktueller Wochentag (z. B. "Donnerstag")
  const [selectedDay, setSelectedDay] = useState(
    new Date().toLocaleDateString("de-DE", { weekday: "long" })
  );

  // State für dynamische Chart-Daten
  const [chartSeries, setChartSeries] = useState(dayChart.series);
  // State für die "statischen" Optionen (Farben, Achsen, etc.)
  const [chartOptions, setChartOptions] = useState(dayChart.options);

  // Mappings zwischen deutschem String und index (0=Sonntag, 1=Montag, …?)
  // Achtung: JS getDay() => Sonntag=0, Montag=1, usw.
  // Du kannst es natürlich so anpassen, dass es zu deinem Locale passt.
  const dayToNumber = {
    Sonntag: 0,
    Montag: 1,
    Dienstag: 2,
    Mittwoch: 3,
    Donnerstag: 4,
    Freitag: 5,
    Samstag: 6,
  };

  const handleDayChange = (value) => {
    setSelectedDay(value);
  };

  // Prüfen, ob ein User Paid oder Free ist
  const isPaid = (user) => {
    // Beispiel-Logik (bei dir ggf. anders):
    return (
      user.subscriptionStatus === "ACTIVE" && 
      (user.subscriptionType === "MONTHLY" || user.subscriptionType === "YEARLY")
    );
  };

  const getUserData = async () => {
    try {
      const users = await getAllUsers();

      // Arrays mit 24 Stunden => 0..23 Uhr
      const freePerHour = Array(24).fill(0);
      const paidPerHour = Array(24).fill(0);

	  let count = 0;

      // Für jeden User schauen wir uns an:
      users.forEach((user) => {
        if (!user.lastLogin) return; // Falls kein lastLogin vorhanden ist -> ignorieren

		count++;

        const loginDate = new Date(user.lastLogin); // z. B. 2024-12-06T17:06:58.566Z
        const weekdayIndex = loginDate.getDay();     // 0(Sonntag) - 6(Samstag)
        const hour = loginDate.getHours();          // 0..23
        // Wochentag in String (z. B. "Montag") konvertieren
        // => Da wir die inverse Richtung brauchen (String -> index),
        //    greifen wir hier auf dayToNumber zurück, 
        //    oder wir vergleichen direkt (weekdayIndex === dayToNumber[selectedDay]).
        
        // Wenn Wochentag dieses Logins == gewählter Tag -> hochzählen
        if (weekdayIndex === dayToNumber[selectedDay]) {
          if (isPaid(user)) {
            paidPerHour[hour]++;
          } else {
            freePerHour[hour]++;
          }
        }
      });

	  console.log(`Anzahl der User: ${count}`);

      // Neue series für den Chart
      const newSeries = [
        {
          name: "Free Accounts",
          data: freePerHour,
        },
        {
          name: "Paid Accounts",
          data: paidPerHour,
        },
      ];

      setChartSeries(newSeries);
    } catch (error) {
      console.error("Fehler beim Laden der User-Daten:", error);
    }
  };

  // useEffect => immer wenn sich 'selectedDay' ändert, laden wir neu
  useEffect(() => {
    getUserData();
  }, [selectedDay]);

  return (
    <>
      <div className='linechart'>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title level={5}>Nutzeraktivität pro Tag</Title>
        </div>
        <Select
          defaultValue={selectedDay}
          onChange={handleDayChange}
          style={{ width: 120 }}
        >
          <Option value='Montag'>Montag</Option>
          <Option value='Dienstag'>Dienstag</Option>
          <Option value='Mittwoch'>Mittwoch</Option>
          <Option value='Donnerstag'>Donnerstag</Option>
          <Option value='Freitag'>Freitag</Option>
          <Option value='Samstag'>Samstag</Option>
          <Option value='Sonntag'>Sonntag</Option>
        </Select>
      </div>

      <ReactApexChart
        className='full-width'
        options={chartOptions}
        series={chartSeries}
        type='area'
        height={350}
        width={"100%"}
      />

      <div className='sales'>
        <ul>
          <li>
            <MinusOutlined /> Paid Accounts
          </li>
          <li>
            <MinusOutlined /> Free Accounts
          </li>
        </ul>
      </div>
    </>
  );
}

export default DailyUserLoginChart;
