// src/components/IntroSection.js
import React from "react";
import { Typography } from "antd";
import "./IntroSection.css";
import Link from "antd/lib/typography/Link";

const { Title, Paragraph } = Typography;

const IntroSection = ({
  title,
  introText,
  descriptionText,
  callToAction,
  style,
  logo1Src,
  logo2Src,
}) => {
  return (
    <div style={{ textAlign: "center", marginBottom: 32, ...style }}>
      <div className="logo-container">
        <div className="logo-wrapper">
          <img src={logo1Src} alt="Logo 1" className="logo" />
        </div>
        <span className="connector">x</span>
        <div className="logo-wrapper">
          <img src={logo2Src} alt="Logo 2" className="logo logo-usc" />
        </div>
      </div>

      <Title level={2} style={{ marginBottom: 24 }}>
        {title}
      </Title>
      <Paragraph style={{ fontSize: "16px", marginBottom: 16 }}>
        {introText}
      </Paragraph>
      <Paragraph style={{ fontSize: "16px", marginBottom: 16 }}>
        {descriptionText}
      </Paragraph>
      <Paragraph style={{ fontSize: "16px", marginBottom: 24 }}>
        {callToAction}
      </Paragraph>
      <Paragraph style={{ fontSize: "16px", marginBottom: 24 }}>
        <strong>
          Mit Klick auf den unteren Knopf und der Erstellung eines Accounts
          stimmst du unseren Nutzungsbedingungen zu. Diese findest du hier:{" "}
          <a href="https://www.mindelevate.app/impressum">
            MindElevate Nutzungsbedingungen
          </a>
        </strong>
      </Paragraph>
      <Link style={{ fontSize: "16px", marginBottom: 24 }}></Link>
    </div>
  );
};

IntroSection.defaultProps = {
  title: "Weniger Stress, mehr Freude - für deine mentale Stärke.",
  introText:
    "MindElevate ist deine App für mentale Gesundheit und innere Zufriedenheit.",
  descriptionText:
    "Mit personalisierten Trainingsplänen, kurzen Videoformaten, einem Feelgood-Feed, einem Chat-Bot mit Tagebuch-Funktion und der Social Battery hilft dir MindElevate, Selbstakzeptanz zu erlangen und herauszufinden, was dir wirklich guttut.",
  callToAction:
    "Hol dir deinen kostenlosen Zugang zur MindElevate App – \ndein Fitnessstudio für dein persönliches Wohlbefinden!",
  link: "",
  style: {},
};

export default IntroSection;
