import React, { useState } from "react";
import { Table, Button } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import moment from "moment";

const NotificationList = ({ allNotifications, onEdit, onDelete }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);

	const columns = [
		{
			title: "Titel",
			dataIndex: "title",
			key: "title",
			sorter: (a, b) => a.title.localeCompare(b.title),
		},
		{
			title: "Inhalt",
			dataIndex: "description",
			key: "description",
			sorter: (a, b) => a.description.localeCompare(b.description),
		},
		{
			title: "Typ",
			dataIndex: "type",
			key: "type",
			sorter: (a, b) => a.type.localeCompare(b.type),
		},
		{
			title: "Details (falls zutreffend)",
			key: "details",
			render: (_, notification) => {
				if (notification.type === "Zeitabhängige Notification") {
					const frequency = notification.notificationFrequency || "-";
					const time = notification.notificationTime
						? moment(notification.notificationTime).format("HH:mm")
						: "-";
					const day =
						notification.notificationFrequency === "Weekly"
							? notification.dayOfWeek || "-"
							: notification.notificationFrequency === "Monthly"
							? moment(notification.dateOfMonth).format("DD.MM.YYYY")
							: "-";
					return (
						<>
							<div>Frequenz: {frequency}</div>
							<div>Zeit: {time}</div>
							{frequency !== "Daily" && <div>Tag: {day}</div>}
						</>
					);
				}
				return "-";
			},
			sorter: (a, b) => {
				const dayA =
					a.type === "Zeitabhängige Notification"
						? a.notificationFrequency === "Weekly"
							? a.dayOfWeek || ""
							: a.notificationFrequency === "Monthly"
							? moment(a.dateOfMonth).unix()
							: ""
						: "";
				const dayB =
					b.type === "Zeitabhängige Notification"
						? b.notificationFrequency === "Weekly"
							? b.dayOfWeek || ""
							: b.notificationFrequency === "Monthly"
							? moment(b.dateOfMonth).unix()
							: ""
						: "";

				// Vergleich für Strings (Wochentage) und Zahlen (Unix-Zeitstempel)
				if (typeof dayA === "string" && typeof dayB === "string") {
					return dayA.localeCompare(dayB);
				}
				if (typeof dayA === "number" && typeof dayB === "number") {
					return dayA - dayB;
				}
				return 0;
			},
		},
		{
			title: "Aktionen",
			key: "actions",
			width: 150,
			render: (_, notification) => (
				<>
					<Button type="primary" onClick={() => onEdit(notification)}>
						Bearbeiten
					</Button>
					<Button
						danger
						type="primary"
						style={{ marginLeft: "10px" }}
						onClick={() => onDelete(notification.id)}
					>
						<DeleteFilled style={{ width: 12 }} />
					</Button>
				</>
			),
		},
	];

	return (
		<Table
			columns={columns}
			dataSource={allNotifications}
			pagination={{
				current: currentPage,
				pageSize: pageSize,
				total: allNotifications.length,
				onChange: (page, newPageSize) => {
					setCurrentPage(page);
					setPageSize(newPageSize);
				},
				showSizeChanger: true,
				pageSizeOptions: ["10", "20", "50", "100"],
				showTotal: (total, range) =>
					`${range[0]}-${range[1]} von ${total} Einträgen`,
			}}
			rowKey={(notification) => notification.id}
			scroll={{ x: 800 }}
		/>
	);
};

export default NotificationList;
