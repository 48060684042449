// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/IntroSection.css */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-bottom: 32px;
}

.logo-wrapper {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center; /* Add this line to center horizontally */
  min-width: 100px; /* Adjust this value as needed */
}

.logo {
  height: 40px;
  width: auto;
  object-fit: contain; /* Add this line */
}

.logo-usc {
  transform: scale(3);
  transform-origin: center; /* Or try other values like top left, bottom right, etc. */
}

.connector {
  font-size: 24px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/coupon/IntroSection.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB,EAAE,yCAAyC;EAClE,gBAAgB,EAAE,gCAAgC;AACpD;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB,EAAE,kBAAkB;AACzC;;AAEA;EACE,mBAAmB;EACnB,wBAAwB,EAAE,0DAA0D;AACtF;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["/* src/components/IntroSection.css */\n.logo-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 24px;\n  margin-bottom: 32px;\n}\n\n.logo-wrapper {\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center; /* Add this line to center horizontally */\n  min-width: 100px; /* Adjust this value as needed */\n}\n\n.logo {\n  height: 40px;\n  width: auto;\n  object-fit: contain; /* Add this line */\n}\n\n.logo-usc {\n  transform: scale(3);\n  transform-origin: center; /* Or try other values like top left, bottom right, etc. */\n}\n\n.connector {\n  font-size: 24px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
