import React from "react";
import { Layout, Row, Col, Typography, Form, Input, Button, message } from "antd";
import { userLogin } from "../services/AuthService";
import { useDispatch } from "react-redux";
import { login } from "../store/slices/authSlice";
// import { useHistory } from "react-router-dom";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignIn = () => {
	const dispatch = useDispatch();
	// const history = useHistory();

	const onFinish = async (values) => {
		const { email, password } = values;

		try {
			const res = await userLogin({ email, password });

			if (res) {
				localStorage.setItem("token", res.accessToken);
				localStorage.setItem("refreshToken", res.refreshToken);
				dispatch(login());
				message.success("Willkommen!");
				window.location.href = "/";
			}
		} catch (error) {
			console.error("Error during login:", error);
			message.error("Login fehlgeschlagen");
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.error("Failed:", errorInfo);
	};

	return (
		<>
			<Layout className='layout-default layout-signin'>
				<Header>
					<div className='header-col header-brand'>
						<h5>Mindelevate Dashboard</h5>
					</div>
				</Header>
				<Content className='signin'>
					<Row gutter={[24, 0]} justify='space-around'>
						<Col
							xs={{ span: 24, offset: 0 }}
							lg={{ span: 6, offset: 0 }}
							md={{ span: 12 }}
						>
							<Title className='mb-15'>Login</Title>
							<Title className='font-regular text-muted' level={5}>
								Gib deine E-Mail und dein Passwort ein, um dich einzuloggen
							</Title>
							<Form
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								layout='vertical'
								className='row-col'
							>
								<Form.Item
									className='username'
									label='Email'
									name='email'
									rules={[
										{
											required: true,
											message: "Bitte gib deine Email-Adresse an",
										},
									]}
								>
									<Input placeholder='Email' />
								</Form.Item>

								<Form.Item
									className='username'
									label='Passwort'
									name='password'
									rules={[
										{
											required: true,
											message: "Bitte gib dein Passwort an",
										},
									]}
								>
									<Input.Password placeholder='Passwort' />
								</Form.Item>

								<Form.Item>
									<Button
										type='primary'
										htmlType='submit'
										style={{ width: "100%" }}
									>
										LOGIN
									</Button>
								</Form.Item>
							</Form>
						</Col>
					</Row>
				</Content>
				<Footer>
					<div className='copyright'>
						© {new Date().getFullYear()}{" "}
						<a
							href='https://www.mindelevate.app/'
							className='font-weight-bold'
							target='_blank'
							rel='noreferrer'
						>
							Mindelevate
						</a>
					</div>
				</Footer>
			</Layout>
		</>
	);
};

export default SignIn;
