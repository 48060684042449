import fetch from "../interceptor/fetchInterceptor";

export function signUpUser(postData) {
  return fetch({
    url: "/auth/signup",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: postData,
  });
}
