import fetch from "../interceptor/fetchInterceptor";

export function userLogin(postData) {
  return fetch({
    url: "/auth/login",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: { ...postData, isAdminPortal: true },
  });
}

export function refreshToken(refreshToken) {
  return fetch({
    url: "/auth/refresh-token",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: {
      refreshToken,
    },
  });
}

export function getMe() {
  const token = localStorage.getItem("token");

  return fetch({
    url: "/users/me",
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
      "public-request": "true",
    },
  });
}
