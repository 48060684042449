import { Switch, Route, Redirect } from "react-router-dom";
import { ConfigProvider } from "antd";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import ContentEditor from "./pages/Content-Editor";
import BusinessRuleEngine from "./pages/BusinessRuleEngine";
import TaskFlows from "./pages/TaskFlows";
import { useSelector } from "react-redux";
import TagsEditor from "./pages/TagsEditor";
import Category from "./pages/Category";
import Achievement from "./pages/Achievement";
import Notification from "./pages/Notification";
import UserLevel from "./pages/UserLevel";
import DiaryQuestions from "./pages/DiaryQuestions";
import Users from "./pages/Users";
import UserManagement from "./components/UserManagement/userManagement";
import USCCode from "./components/USCCode";
import AppFooter from "./components/appFooter";

// Protected Route component
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth.isAuthenticated);
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <Main>
            <Component {...props} />
          </Main>
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

function App() {
  const auth = useSelector((state) => state.auth.isAuthenticated);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#23C89A",
        },
      }}
    >
      <div className="App">
        <Switch>
          {/* Public routes */}
          <Route exact path="/signin" component={SignIn} />

          {/* Default route (USC) */}
          <Route
            exact
            path="/"
            render={() => (!auth ? <USCCode /> : <Redirect to="/dashboard" />)}
          />

          {/* Protected routes */}
          <ProtectedRoute exact path="/dashboard" component={Home} />
          <ProtectedRoute exact path="/users" component={Users} />
          <ProtectedRoute
            exact
            path="/business-rule-engine"
            component={BusinessRuleEngine}
          />
          <ProtectedRoute
            exact
            path="/content-editor"
            component={ContentEditor}
          />
          <ProtectedRoute exact path="/task-flows" component={TaskFlows} />
          <ProtectedRoute exact path="/tags-editor" component={TagsEditor} />
          <ProtectedRoute exact path="/categories" component={Category} />
          <ProtectedRoute exact path="/achievement" component={Achievement} />
          <ProtectedRoute exact path="/notification" component={Notification} />
          <ProtectedRoute exact path="/user-level" component={UserLevel} />
          <ProtectedRoute
            exact
            path="/diary-questions"
            component={DiaryQuestions}
          />
          <ProtectedRoute
            exact
            path="/userManagement"
            component={UserManagement}
          />
        </Switch>

        <AppFooter />
      </div>
    </ConfigProvider>
  );
}

export default App;
