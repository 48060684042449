import React from "react";
import { useState } from "react";
import { Button, Typography, Upload } from "antd";
import Papa from "papaparse";
import { UploadOutlined } from "@ant-design/icons";
import "../../assets/styles/main.css";

import { signUpUser } from "../../services/UserManagementService";

const { Title } = Typography;
const { Dragger } = Upload;

const UserManagement = () => {
  const [fileList, setFileList] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const beforeUpload = (file) => {
    return false;
  };

  function handleUserCreation() {
    const file = fileList[0].originFileObj;
    // console.log(file);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (result) {
        const resArr = [];
        result.data.forEach((d) => {
          resArr.push(d);
        });
        setCsvData(resArr);
        console.log(resArr);
        console.log(resArr.length);
      },
    });
  }
  async function handleSignup() {
    for (const element of csvData) {
      try {
        signUpUser(element);
      } catch {
        return "ERROOOR";
      }
    }
  }

  return (
    <>
      <Title level={2}>CSV Datei zum Erstellen von Nutzern hochladen:</Title>
      <Title level={4}>
        Folgendes Format für Zeile 1: email,password,userRole,loginMethod
      </Title>

      <Title level={4}>test@test.com,kekw12345,PAID_USER,EMAIL</Title>
      <Dragger
        fileList={fileList}
        onChange={handleUploadChange}
        beforeUpload={beforeUpload}
        accept=".csv"
        maxCount={1}
      >
        <Button style={{ fontSize: "150%" }} icon={<UploadOutlined />}>
          Click to Upload
        </Button>
      </Dragger>
      <Button onClick={handleUserCreation}>Step 1: Read User Info</Button>
      <Button onClick={handleSignup}>Step 2: Signup Users</Button>
    </>
  );
};

export default UserManagement;
