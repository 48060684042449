import fetch from "../interceptor/fetchInterceptor";

export function getAllUsers() {
	return fetch({
		url: "/users/all",
		method: "get",
		headers: {
			"public-request": "true",
		},
	});
}
