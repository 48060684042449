import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Typography, Select } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import lineChart from "./configs/lineChart";
import { getAllUsers } from "../../services/UserService";

function LineChart() {
  const { Title } = Typography;
  const { Option } = Select;

  const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear().toString());

  // 1) Wir legen einen eigenen State für die Chart-Optionen an,
  //    damit wir dort dynamisch die series aktualisieren können.
  const [chartOptions, setChartOptions] = useState(lineChart.options);

  // 2) Hier speichern wir die Chart-Datenreihen (series)
  //    und initialisieren sie z. B. leer:
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Free Accounts",
      data: Array(12).fill(0),
    },
    {
      name: "Paid Accounts",
      data: Array(12).fill(0),
    },
  ]);

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const getUserData = async () => {
    try {
      const users = await getAllUsers();
      
      // Arrays für Jan–Dez (jeweils Index 0–11)
      const freeUsersCountPerMonth = Array(12).fill(0);
      const paidUsersCountPerMonth = Array(12).fill(0);

      // Hilfsfunktion: Prüfen, ob ein User "Paid" ist
      const isPaid = (user) => {
        return (
          user.subscriptionStatus === "ACTIVE" &&
          (user.subscriptionType === "MONTHLY" || user.subscriptionType === "YEARLY")
        );
      };

      // 1) Alle User durchgehen
      users.forEach((user) => {
        // 2) Jahr ermitteln, in dem der User erstellt wurde
        const createdAt = new Date(user.createdAt);
        const yearOfCreation = createdAt.getFullYear();

        // 3) Wenn es das ausgewählte Jahr ist
        if (yearOfCreation.toString() === selectedYear) {
          // Monat ermitteln (0-basiert)
          const monthIndex = createdAt.getMonth();
          // 4) Paid vs. Free
          if (isPaid(user)) {
            paidUsersCountPerMonth[monthIndex]++;
          } else {
            freeUsersCountPerMonth[monthIndex]++;
          }
        }
      });

      // 5) State für series updaten
      setChartSeries([
        {
          name: "Free Accounts",
          data: freeUsersCountPerMonth,
        },
        {
          name: "Paid Accounts",
          data: paidUsersCountPerMonth,
        },
      ]);
    } catch (error) {
      console.error("Fehler beim Laden der User-Daten:", error);
    }
  };

  // useEffect: Daten laden, wenn sich das ausgewählte Jahr ändert
  useEffect(() => {
    getUserData();
  }, [selectedYear]);

  return (
    <>
      <div className="linechart">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title level={5}>Nutzerzuwachs</Title>
        </div>
        <Select
          defaultValue={selectedYear}
          onChange={handleYearChange}
          style={{ width: 120 }}
        >
          <Option value="2025">2025</Option>
          <Option value="2024">2024</Option>
          {/* weitere Jahre falls nötig */}
        </Select>
      </div>

      <ReactApexChart
        className="full-width"
        options={chartOptions}
        series={chartSeries}
        type="area"
        height={350}
        width={"100%"}
      />
      <div className="sales">
        <ul>
          <li><MinusOutlined /> Paid Accounts</li>
          <li><MinusOutlined /> Free Accounts</li>
        </ul>
      </div>
    </>
  );
}

export default LineChart;
