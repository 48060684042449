import { useEffect, useState } from "react";
import UsersList from "../components/Users/UsersList";
import { Card } from "antd";
import { getAllUsers } from "../services/UserService";


const Users = () => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const fetchUsers = async () => {
			const result = await getAllUsers();

			setUsers(result);
		};

		fetchUsers();
	}, []);

	return (
		<>
			<Card
				title={
					<>
						<h1
							style={{
								fontSize: "2rem",
								fontWeight: "600",
							}}
						>
							Nutzer
						</h1>
					</>
				}
			>
				<UsersList users={users} />
			</Card>
		</>
	);
};
export default Users;
