import React, { useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  Button,
  Card,
  message,
  Steps,
  Result,
} from "antd";
import service from "../interceptor/fetchInterceptor";
import axios from "axios";
import IntroSection from "./coupon/IntroSection";
import SuccessScreen from "./coupon/SuccessScreen.js";

import MindElevateLogo from "../assets/images/MindElevateLogo.svg";
import uscLogo from "../assets/images/UrbanSportsClub_Logo_Combo_Black.svg";

const { Step } = Steps;

const USCSignup = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [validCode, setValidCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(""); // Store email for success screen

  // First step: Validate USC Code
  const validateCode = async (values) => {
    setLoading(true);
    try {
      const response = await service.post("/coupon/validate", {
        couponCode: values.uscCode,
      });

      if (response.isValid === true) {
        setValidCode(values.uscCode);
        setCurrentStep(2);
        message.success("Code validated successfully!");
      } else {
        message.error("Invalid USC code");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle specific status codes
        switch (error.response?.status) {
          case 404:
            message.error("Coupon validation endpoint not found");
            break;
          case 400:
            message.error("Coupon already redeemed!");
            break;
          case 401:
            message.error("Unauthorized access");
            break;
          case 500:
            message.error("Server error, please check again and message us!");
            break;
          default:
            message.error(
              error.response?.data?.message ||
                "Error validating code: " + error.message,
            );
        }
      }
    } finally {
      setLoading(false);
    }
  };
  // Second step: Create account with validated code
  const createAccount = async (values) => {
    setLoading(true);
    try {
      // If you need the full response object
      const { data, status, headers } = await service.post(
        "/coupon/signup",
        {
          couponCode: validCode,
          email: values.email,
          password: values.password,
          firstname: values.firstname,
          lastname: values.lastname,
          userRole: "PAID_USER",
          loginMethod: "EMAIL",
          phoneNumber: values.phoneNumber,
          birthday: values.birthday,
        },
        {
          headers: {
            "public-request": "true",
          },
          transformResponse: [(data) => data], // Prevents automatic parsing
        },
      );

      console.log("Full response:", { data, status, headers });
      setUserEmail(values.email); // Store email for success screen
      message.success("Account erfolgreich erstellt!");
      setCurrentStep(3); // Move to success screen
      // Handle successful creation
    } catch (error) {
      // ... error handling as above
    } finally {
      setLoading(false);
    }
  };

  // Code validation form
  const CodeValidationForm = () => (
    <Form name="usc_code_validation" onFinish={validateCode} layout="vertical">
      <Form.Item
        label="USC Code"
        name="uscCode"
        rules={[{ required: true, message: "Bitte den Code eingeben" }]}
      >
        <Input placeholder="Bitte den Code eingeben" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Code validieren
        </Button>
      </Form.Item>
    </Form>
  );

  // Account creation form
  const AccountCreationForm = () => (
    <Form name="usc_signup" onFinish={createAccount} layout="vertical">
      <Form.Item
        label="Vorname"
        name="firstname"
        rules={[{ required: true, message: "Bitte den Vornamen eingeben" }]}
      >
        <Input placeholder="Vorname eingeben" />
      </Form.Item>
      <Form.Item
        label="Nachname"
        name="lastname"
        rules={[{ required: true, message: "Bitte den Nachnamen eingeben" }]}
      >
        <Input placeholder="Nachname eingeben" />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Bitte die Email eingeben" },
          { type: "email", message: "Bitte eine valide Email eingeben" },
        ]}
      >
        <Input placeholder="Email eingeben" />
      </Form.Item>

      <Form.Item
        label="Passwort"
        name="password"
        rules={[
          { required: true, message: "Bitte ein Passwort eingeben" },
          { min: 8, message: "Passwort muss mindestens 8 Zeichen lang sein" },
        ]}
      >
        <Input.Password placeholder="Passwort eingeben" />
      </Form.Item>

      <Form.Item
        label="Passwort bestätigen"
        name="confirmPassword"
        dependencies={["password"]}
        rules={[
          { required: true, message: "Bitte Passwort bestätigen!" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Passwörter stimmen nicht überein!");
            },
          }),
        ]}
      >
        <Input.Password placeholder="Passwort bestätigen" />
      </Form.Item>
      <Form.Item
        label="Geburtsdatum"
        name="birthday"
        rules={[
          { required: false, message: "Bitte das Geburtsdatum eingeben" },
        ]}
      >
        <DatePicker
          placeholder="Geburtsdatum auswählen"
          format="DD.MM.YYYY"
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        label="Telefonnummer"
        name="phoneNumber"
        rules={[
          { required: false, message: "Bitte die Telefonnummer eingeben" },
          {
            pattern: /^(\+49|0)[1-9][0-9]*$/,
            message: "Bitte eine gültige deutsche Telefonnummer eingeben",
          },
        ]}
      >
        <Input placeholder="z.B. +49123456789 oder 0123456789" maxLength={15} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Account erstellen
        </Button>
      </Form.Item>
    </Form>
  );

  const steps = [
    { title: "Info" },
    { title: "Code" },
    { title: "Account" },
    { title: "Erfolg" },
  ];

  return (
    <div style={{ maxWidth: 800, margin: "24px auto", padding: "0 16px" }}>
      <Card
        title={
          <div style={{ textAlign: "center", width: "100%" }}>
            MindElevate x Urban Sports Club Anmeldung
          </div>
        }
        style={{ maxWidth: 500, margin: "0 auto" }}
      >
        {/* Responsive Steps */}
        <Steps
          current={currentStep}
          style={{ marginBottom: 24 }}
          size="small"
          responsive={true}
          items={steps}
        />

        {currentStep === 0 && (
          <>
            <IntroSection logo1Src={MindElevateLogo} logo2Src={uscLogo} />
            <div style={{ marginTop: 24, textAlign: "center" }}>
              <Button
                type="primary"
                onClick={() => setCurrentStep(1)}
                size="large"
                block
              >
                Weiter
              </Button>
            </div>
          </>
        )}
        {currentStep === 1 && <CodeValidationForm />}
        {currentStep === 2 && (
          <>
            <AccountCreationForm />
            <div style={{ marginTop: 12 }}>
              <Button
                type="link"
                onClick={() => {
                  setCurrentStep(0);
                  setValidCode(null);
                }}
              >
                Anderen Code einlösen
              </Button>
            </div>
          </>
        )}
        {currentStep === 3 && <SuccessScreen userEmail={userEmail} />}
      </Card>
    </div>
  );
};

export default USCSignup;
