import React, { useEffect, useState } from "react";
import { Card, Col, Row, Typography } from "antd";

import UsergrowChart from "../components/chart/UsergrowChart";
import DailyUserLoginChart from "../components/chart/DailyUserLoginChart";
import { getAllUsers } from "../services/UserService";

function Home() {
	const { Title } = Typography;

	// Preise
	const MONTHLY_PRICE = 9.99;
	const YEARLY_PRICE_PER_MONTH = 99.99 / 12; // ~8.33

	// Aktuelle Werte
	const [monthIncome, setMonthIncome] = useState(0);
	const [todaysSignups, setTodaysSignups] = useState(0);
	const [newUsers, setNewUsers] = useState(0);
	const [newPaidAccounts, setNewPaidAccounts] = useState(0);

	// **Neu**: Gesamtwerte
	const [totalUsers, setTotalUsers] = useState(0);
	const [totalUsersPaid, setTotalUsersPaid] = useState(0);
	const [totalUsersFree, setTotalUsersFree] = useState(0);

	// Prozent-Deltas
	const [monthIncomeDelta, setMonthIncomeDelta] = useState(0);
	const [todaysSignupsDelta, setTodaysSignupsDelta] = useState(0);
	const [newUsersDelta, setNewUsersDelta] = useState(0);
	const [newPaidAccountsDelta, setNewPaidAccountsDelta] = useState(0);

	useEffect(() => {
		fetchDashboardData();
	}, []);

	/**
	 * getSignupsForDay:
	 * Zählt, wie viele User an einem Datum (z.B. "2025-01-09") erstellt wurden
	 */
	const getSignupsForDay = (users, dayString) => {
		let count = 0;
		users.forEach((u) => {
			const createdDate = new Date(u.createdAt).toISOString().split("T")[0];
			if (createdDate === dayString) {
				count++;
			}
		});
		return count;
	};

	/**
	 * getStatsForMonth:
	 *   - Wie viele neue User in (year, month)?
	 *   - Wie viele davon Paid?
	 *   - Wie viel Einnahmen durch diese neuen Paid-User?
	 */
	const getStatsForMonth = (users, year, month) => {
		let tempNewUsers = 0;
		let tempNewPaid = 0;
		let tempIncome = 0;

		users.forEach((user) => {
			const created = new Date(user.createdAt);
			if (created.getFullYear() === year && created.getMonth() === month) {
				// In diesem Monat angelegt
				tempNewUsers++;

				const isPaid =
					user.subscriptionStatus === "ACTIVE" &&
					(user.subscriptionType === "MONTHLY" || user.subscriptionType === "YEARLY");

				if (isPaid) {
					tempNewPaid++;
					if (user.subscriptionType === "MONTHLY") {
						tempIncome += MONTHLY_PRICE;
					} else {
						tempIncome += YEARLY_PRICE_PER_MONTH;
					}
				}
			}
		});

		return {
			newUsers: tempNewUsers,
			newPaid: tempNewPaid,
			income: tempIncome,
		};
	};

	/**
	 * calcDeltaPercent:
	 *   Prozentuale Änderung: (cur - prev) / prev * 100
	 *   Falls prev=0 => +100%, wenn cur>0
	 */
	const calcDeltaPercent = (cur, prev) => {
		if (prev === 0) {
			return cur > 0 ? 100 : 0;
		}
		return ((cur - prev) / prev) * 100;
	};

	// -----------------------------------------------------------
	// Dashboard-Daten laden und Deltas berechnen
	// -----------------------------------------------------------
	const fetchDashboardData = async () => {
		try {
			const users = await getAllUsers();

			// Aktueller Zeitpunkt
			const now = new Date();
			const currentYear = now.getFullYear();
			const currentMonth = now.getMonth(); // 0-based
			const todayString = now.toISOString().split("T")[0];

			// Vormonat berechnen (ggf. Jahr reduzieren, wenn unter 0)
			let prevMonth = currentMonth - 1;
			let prevMonthYear = currentYear;
			if (prevMonth < 0) {
				prevMonth = 11; // Dezember
				prevMonthYear = currentYear - 1;
			}

			// ============ (A) Heutige Anmeldungen vs gleicher Tag Vormonat ============
			const signupsToday = getSignupsForDay(users, todayString);

			const lastMonthSameDay = new Date(now);
			lastMonthSameDay.setMonth(lastMonthSameDay.getMonth() - 1);
			const lastMonthSameDayString = lastMonthSameDay.toISOString().split("T")[0];
			const signupsPrevDay = getSignupsForDay(users, lastMonthSameDayString);

			const signupsDelta = calcDeltaPercent(signupsToday, signupsPrevDay);

			// ============ (B) Monats-Stats: aktueller Monat vs Vormonat ============
			// Aktueller Monat
			const curStats = getStatsForMonth(users, currentYear, currentMonth);
			// Vormonat
			const prevStats = getStatsForMonth(users, prevMonthYear, prevMonth);

			const newUsersDeltaTemp = calcDeltaPercent(curStats.newUsers, prevStats.newUsers);
			const newPaidDeltaTemp = calcDeltaPercent(curStats.newPaid, prevStats.newPaid);
			const incomeDeltaTemp = calcDeltaPercent(curStats.income, prevStats.income);

			// ============ (C) Gesamt-User berechnen ============
			const allUsers = users.length;
			const paidUsers = users.filter(
				(u) =>
					u.subscriptionStatus === "ACTIVE" &&
					(u.subscriptionType === "MONTHLY" || u.subscriptionType === "YEARLY")
			).length;
			const freeUsers = allUsers - paidUsers;

			setTotalUsers(allUsers);
			setTotalUsersPaid(paidUsers);
			setTotalUsersFree(freeUsers);

			// ============ (D) State updaten ============
			setTodaysSignups(signupsToday);
			setTodaysSignupsDelta(signupsDelta);

			setNewUsers(curStats.newUsers);
			setNewUsersDelta(newUsersDeltaTemp);

			setNewPaidAccounts(curStats.newPaid);
			setNewPaidAccountsDelta(newPaidDeltaTemp);

			setMonthIncome(curStats.income);
			setMonthIncomeDelta(incomeDeltaTemp);
		} catch (error) {
			console.error("Fehler beim Laden der Dashboard-Daten:", error);
		}
	};

	// Hilfsfunktion zum Formatieren der Prozentwerte
	const formatDelta = (val) => {
		const sign = val >= 0 ? "+" : "";
		return sign + val.toFixed(1) + "%";
	};

	// -----------------------------------------------------------
	// ICONS und LAYOUT
	// -----------------------------------------------------------

	// Hier nur die Prozentwerte dynamisch aus den States
	const count = [
		{
			today: "Monatliche Einnahmen",
			title: monthIncome.toFixed(2) + " €",
			persent: formatDelta(monthIncomeDelta),
			icon: (
				<svg
					width='22'
					height='22'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z'
						fill='#fff'
					></path>
					<path
						d='M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z'
						fill='#fff'
					></path>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z'
						fill='#fff'
					></path>
				</svg>
			),
			bnb: "bnb2",
		},
		{
			today: "Heutige Anmeldungen",
			title: todaysSignups.toString(),
			persent: formatDelta(todaysSignupsDelta),
			icon: (
				<svg
					width='22'
					height='22'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z'
						fill='#fff'
					></path>
					<path
						d='M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z'
						fill='#fff'
					></path>
					<path
						d='M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z'
						fill='#fff'
					></path>
					<path
						d='M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z'
						fill='#fff'
					></path>
				</svg>
			),
			bnb: "bnb2",
		},
		{
			today: "Neue Nutzer (diesen Monat)",
			title: newUsers.toString(),
			persent: formatDelta(newUsersDelta),
			icon: (
				<svg
					width='22'
					height='22'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z'
						fill='#fff'
					></path>
				</svg>
			),
			bnb: "bnb2",
		},
		{
			today: "Neue Paid Accounts",
			title: newPaidAccounts.toString(),
			persent: formatDelta(newPaidAccountsDelta),
			icon: (
				<svg
					width='22'
					height='22'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z'
						fill='#fff'
					></path>
				</svg>
			),
			bnb: "bnb2",
		},

		// =========================
		// NEUE KACHELN
		// =========================
		{
			today: "Gesamtuser",
			title: totalUsers.toString(),
			// Hier ggf. kein Prozent-Delta => wir lassen es leer oder "persent: ''"
			persent: "",
			icon: (
				// Selbes Icon wie bei "Heutige Anmeldungen" (Beispiel) oder ein anderes
				<svg
					width='22'
					height='22'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z'
						fill='#fff'
					></path>
					<path
						d='M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z'
						fill='#fff'
					></path>
					<path
						d='M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z'
						fill='#fff'
					></path>
					<path
						d='M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z'
						fill='#fff'
					></path>
				</svg>
			),
			bnb: "bnb2",
		},
		{
			today: "Gesamtuser Paid",
			title: totalUsersPaid.toString(),
			persent: "",
			icon: (
				// Beispiel: Selbes Icon wie "Neue Paid Accounts"
				<svg
					width='22'
					height='22'
					viewBox='0 0 20 20'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z'
						fill='#fff'
					></path>
				</svg>
			),
			bnb: "bnb2",
		},
		{
			today: "Gesamtuser Free",
			title: totalUsersFree.toString(),
			persent: "",
			icon: (
				// Beispiel: Das "Herz"-Icon aus "Neue Nutzer (diesen Monat)"
				<svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 640 512'
					width='22'
					height='22'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-86.8-68 0-17.1 0-131.4c-4 1-8 1.8-12.3 2.3c0 0 0 0-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-12.4 0-24.3-1.9-35.4-5.3l0 100.3L301.2 210.7c7-4.4 13.3-9.7 18.8-15.7c15.9 17.6 39.1 29 65.2 29c26.2 0 49.3-11.4 65.2-29c16 17.6 39.1 29 65.2 29c4.1 0 8.1-.3 12.1-.8c55.5-7.4 81.8-72.5 52.1-119.4L522.3 13.1C517.2 5 508.1 0 498.4 0L141.6 0c-9.7 0-18.8 5-23.9 13.1l-22.7 36L38.8 5.1zm73.4 218.1c4 .5 8.1 .8 12.1 .8c11 0 21.4-2 31-5.6L48.9 134.5c-6.1 40.6 19.5 82.8 63.3 88.7zM160 384l0-133.4c-11.2 3.5-23.2 5.4-35.6 5.4c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3L96 384l0 64c0 35.3 28.7 64 64 64l320 0c12.9 0 24.8-3.8 34.9-10.3L365.5 384 160 384z'
						fill='#fff'
					/>
				</svg>
			),
			bnb: "bnb2",
		},
	];

	return (
		<>
			<div className='layout-content'>
				<Row className='rowgap-vbox' gutter={[24, 0]}>
					{count.map((c, index) => (
						<Col key={index} xs={24} sm={24} md={12} lg={6} xl={6} className='mb-24'>
							<Card bordered={false} className='criclebox '>
								<div className='number'>
									<Row align='middle' gutter={[24, 0]}>
										<Col xs={18}>
											<span style={{ fontWeight: "bold" }}>{c.today}</span>
											<Title level={2}>
												{c.title}{" "}
												{c.persent && (
													<small className={c.bnb}>{c.persent}</small>
												)}
											</Title>
										</Col>
										<Col xs={6}>
											<div className='icon-box'>{c.icon}</div>
										</Col>
									</Row>
								</div>
							</Card>
						</Col>
					))}
				</Row>

				<Row gutter={[24, 0]}>
					<Col xs={24} sm={24} md={12} lg={12} xl={24} className='mb-24'>
						<Card bordered={false} className='criclebox h-full'>
							<UsergrowChart />
						</Card>
					</Col>
				</Row>

				<Row gutter={[24, 0]}>
					<Col xs={24} sm={24} md={12} lg={12} xl={24} className='mb-24'>
						<Card bordered={false} className='criclebox h-full'>
							<DailyUserLoginChart />
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default Home;
